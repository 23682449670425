import React from "react";
import styled from "styled-components";
import {
	setFlex,
	setRem,
	setColor,
	setLetterSpacing,
	media,
} from "../styles/styles";
import Layout from "../components/layout";
import links from "../constants/links";
import SEO from "../components/seo";

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<IndexWrapper>
			<section>
				<h1>Alex Curtin</h1>
				<h2>Software Developer</h2>
				<div>
					{links.map((link) => (
						<a href={link.path} target="_blank" rel="noreferrer">
							{link.icon}
						</a>
					))}
				</div>
			</section>
		</IndexWrapper>
	</Layout>
);

const IndexWrapper = styled.main`
  height: 100vh;
  width: 90%;
  margin: 0 auto;
  ${setFlex({ y: "baseline" })};
  padding-top: ${setRem(100)};
  section {
    color: ${setColor.darkGrey};
    text-align: left;
    ${setLetterSpacing()};
    a {
      margin: 0 ${setRem()};
    }
    svg {
      color: ${setColor.darkGrey};
      font-size: ${setRem(24)};
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  h1 {
    font-size: ${setRem(80)};
    line-height: ${setRem(80)};
  }
  h2 {
    font-size: ${setRem(55)};
    margin-bottom: ${setRem()};
  }
  ${media.phone`
    h1 {
      font-size: ${setRem(60)};
    line-height: ${setRem(60)};
    }
    h2 {
    font-size: ${setRem(35)};
    margin-bottom: ${setRem(24)};
  }
  `}
`;

export default IndexPage;
